<template>
  <div class="pages__create">
    <div class="">
      <v-row class="mt-5" no-gutters>
        <v-col cols="12" sm="12" xs="12" class="mb-5 ml-5">
          <redirect class="mb-5" title="Pages" route="website.pages" />
          <div class="page-header">
            <h2 class="page-title">Add page</h2>
          </div>
          <Notification type="error" :messages="errors" v-if="errors.length > 0" />
          <!-- <GroupSubmit :callbackCancel="onCancel" :callbackSubmit="onSubmit" /> -->
        </v-col>
        <v-col cols="12" md="8" sm="12" xs="12" class="px-5">
          <v-row no-gutters>
            <v-col cols="12" sm="12" xs="12" class="elevation-form pa-3">
              <h3>Page details</h3>
              <div class="mt-5">
                <InputText
                  :fieldName="'Title'"
                  label="Title"
                  :model="['pageData', 'title']"
                  :isValidate="true"
                  :min="3"
                  :max="100"
                />
              </div>
              <div class="mt-5">
                <TextEditor label="Content" :callback="setContent" />
              </div>
            </v-col>
            <v-col cols="12" sm="12" xs="12" class="elevation-form pa-3 mt-5">
              <template>
                <v-row justify="center">
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header disable-icon-rotate>
                        <div>
                          <h3>Search engine listing preview</h3>
                          <p class="mt-5">
                            Add a title and description to see how this collection might appear in a search engine
                            listing
                          </p>
                        </div>
                        <template v-slot:actions>
                          <span class="btn-link">Edit website SEO</span>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template>
                          <div>
                            <span>Page title</span>
                            <v-text-field v-model="pageData.searchEngineTitle"></v-text-field>
                          </div>
                          <div>
                            <span>Meta description</span>
                            <v-textarea v-model="pageData.searchEngineMetaDescription"></v-textarea>
                          </div>
                          <div>
                            <span>URL and handle</span>
                            <v-text-field :prefix="getDomain()" v-model="pageData.handle"></v-text-field>
                          </div>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-row no-gutters>
            <v-col class="elevation-form pa-3" cols="12" xs="12">
              <h3>Visibility</h3>
              <v-radio-group v-model="pageData.published">
                <v-radio label="Visible (as of just now)" :value="true"></v-radio>
                <v-radio label="Hidden" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="elevation-form pa-3" cols="12" xs="12">
              <h3>Show title</h3>
              <v-radio-group v-model="pageData.isShowTitle">
                <v-radio label="Show Title" :value="true"></v-radio>
                <v-radio label="Hidden Title" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col class="elevation-form pa-3 mt-5" cols="12" xs="12">
              <h3>Template</h3>
              <p>Select a template for this page.</p>
              <InputText
                :model="['pageData', 'template']"
                itemText="name"
                itemValue="type"
                :itemsSelect="items"
                typeComponent="select"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="text-end" cols="12" sm="12" xs="12">
          <v-divider class="my-5"></v-divider>
          <v-btn @click="$router.push({ name: 'website.pages' })" color="" class="mx-5">Cancel</v-btn>
          <v-btn @click="onSubmit" :loading="isLoading" :disabled="isLoading" color="primary">Save</v-btn>
        </v-col>
      </v-row>
    </div>
    <!-- <BeforeLeavePage /> -->
  </div>
</template>
<script>
import InputText from '@/views/website/components/inputs/InputDefault';
import TextEditor from '@/components/form/Wysiwyg';
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import Redirect from '@/components/RedirectTo';
import resourceBeforeLeavePage from '@/mixins/before-change-router';
import { pageService } from '@/apis/page.s';
import slugify from '@/helpers/slugify';
import Notification from '@/components/Notification';

export default {
  components: {
    InputText,
    TextEditor,
    Redirect,
  },
  // mixins: [resourceBeforeLeavePage],
  validations: {
    pageData: {
      title: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(100),
      },
      searchEngineTitle: {
        required: false,
      },
      template: {
        required: false,
      },
    },
  },
  data() {
    return {
      pageData: {
        title: '',
        searchEngineTitle: '',
        bodyHtml: '',
        handle: '',
        searchEngineMetaDescription: '',
        template: 'page',
        published: true,
        isShowTitle: true,
      },
      items: [
        { type: 'page', name: 'Page' },
        { type: 'contact-page', name: 'Contact Page' },
        { type: 'review-page', name: 'Review Page' },
        { type: 'order-tracking-page', name: 'Order Tracking Page' },
      ],
      validate: [{ type: 'required' }, { type: 'maxLength', min: 3 }, { type: 'maxLength', max: 100 }],
      isLoading: false,
      errors: [],
    };
  },
  mounted() {},
  methods: {
    setContent(content) {
      this.pageData.bodyHtml = content;
    },
    async onSubmit() {
      this.$v.$touch();
      this.isLoading = true;
      if (!this.$v.$invalid) {
        try {
          let pageData = await pageService.create(this.pageData);
          if (pageData) {
            this.$router.push({ name: 'website.pages.update', params: { id: pageData.data.id } });
            console.log('success');
          }

          this.isLoading = false;
        } catch (error) {
          this.errors = error.response.data.message || [];
          this.isLoading = false;
        }
      } else {
        this.isLoading = false;
      }
    },
    onCancel() {},
    getDomain() {
      return `https://${this.$store.state.auth.accountInfo.storeDomain}/pages/`;
    },
  },
  watch: {
    'pageData.title': function() {
      this.pageData.handle = slugify(this.pageData.title);
      this.pageData.searchEngineTitle = this.pageData.title;
    },
    'pageData.bodyHtml': function() {
      this.pageData.searchEngineMetaDescription = this.pageData.bodyHtml;
    },
  },
};
</script>
<style lang="scss">
.pages__create {
  .btn-link {
    color: var(--v-primary-base);
    &:hover {
      text-decoration: none;
    }
  }
  .v-expansion-panel:before {
    box-shadow: none;
  }
  .v-expansion-panel-header__icon {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
</style>
